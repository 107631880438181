"use client";

import {
  Button,
  Frame,
  Text,
  X,
  Y,
  Image,
  tw,
  t,
  ScrollArea,
} from "@reframe.so/blocks";

import { ArrowRightIcon } from "@reframe.so/icons/icons/ArrowRightIcon";
import { CheckSquareIcon } from "@reframe.so/icons/icons/CheckSquareIcon";
import { ChevronRightIcon } from "@reframe.so/icons/icons/ChevronRightIcon";
import { FacebookIcon } from "@reframe.so/icons/icons/FacebookIcon";
import { TwitterIcon } from "@reframe.so/icons/icons/TwitterIcon";
import { YoutubeIcon } from "@reframe.so/icons/icons/YoutubeIcon";

import Link from "next/link";
import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { getCalApi } from "@calcom/embed-react";
import { Logo } from "@src/components/logo";
import { LinkButton } from "@src/components/link-button";
import { useMixpanel } from "@src/mixpanel/context";
import { EVENT_CONST } from "@src/mixpanel/constant";

type PricingProps = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  price: React.ReactNode;
  features: React.ReactNode[];
};

const Purple = tw(
  Text,
  t`bg-gradient-to-r from-indigo-400 via-purple-400 to-pink-400 bg-clip-text text-transparent`
);

const Orange = tw(
  Text,
  t` bg-gradient-to-l from-indigo-400 from-10% via-sky-400 via-30% to-emerald-300 to-90% bg-clip-text text-transparent`
);

const Iframe = tw("iframe", t`w-full h-full border-none`);

const Content1 = {
  header: {
    whatItIs: <>No-code AI app solution</>,
    clearOutcome: (
      <>
        The <Orange>missing</Orange> platform to build your{" "}
        <Purple>AI apps</Purple>
      </>
    ),
  },
};

const Content2 = {
  header: {
    overview: (
      <>
        Create <Orange>your own</Orange> ChatGPT
      </>
    ),
    subheader: <>Create. Customize. Integrate.</>,
  },
  firstSolution: {
    title: <>An AI for everything</>,
    description: (
      <>
        Build chatbots for any use case, from customer support to sales and more
      </>
    ),
  },
  secondSolution: {
    title: <>Train with any data</>,
    description: (
      <>
        Connect any data source to your chatbot, from your website to hyper
        personalized customer data
      </>
    ),
  },
  thirdSolution: {
    title: (
      <>
        <Purple>Integrate</Purple> Everywhere
      </>
    ),
    description: (
      <>Integrate your chatbot with any platform and boost your revenue</>
    ),
  },
};

const pricing: PricingProps[] = [
  {
    title: "Hobby",
    subtitle: "Personal or hobby projects",
    price: "Free",
    features: [
      "10 personal chatbots",
      "Publish on your website",
      "1000 messages per chatbot",
      "100 users per chatbot",
      "5 file uploads / month",
    ],
  },
  {
    title: "Grow",
    subtitle: "Startups and small businesses",
    price: (
      <>
        $99<Text>/month</Text>
      </>
    ),
    features: [
      "Unlimited messages and users",
      "Crawl your websites",
      "500 file uploads / month",
      "Infinite memory in chatbots",
      "Customize your chat widget",
      "Early access to new features",
      "Priority feature requests",
    ],
  },
  {
    title: "Max",
    subtitle: "Agencies, teams with custom security and performance needs",
    price: (
      <>
        $399<Text>/month</Text>
      </>
    ),
    features: [
      "Unlimited published chatbots",
      "Unlimited file uploads",
      "White-labeled branding for chatbots",
      "Custom trained fine-tuned models",
      "Custom integrations with any application",
    ],
  },
];

const Testimonials = [
  {
    name: "DJ",
    userIcon: "/assets/images/user.svg",
    companyName: "DJ's Startup",
    description: (
      <>
        I was able to build a chatbot for my startup in a matter of days. I
        couldn't have done it without ChatGPT.
      </>
    ),
  },
  {
    name: "Subhasish",
    userIcon: "/assets/images/user.svg",
    companyName: "Subhasish's Startup",
    description: (
      <>
        I earnt 100k in two days using this product. I'm a millionaire now. I
        earnt 100k in two days using this product. I'm a millionaire now. I
        earnt 100k in two days using this product. I'm a millionaire now.
      </>
    ),
  },
  {
    name: "Nirob",
    userIcon: "/assets/images/user.svg",
    companyName: "Nirob's Startup",
    description: (
      <>This product is great. Give it 5 stars, and all your money.</>
    ),
  },
];

const FooterSections = [
  {
    title: <> Product</>,
    subSections: [
      {
        title: <> Pricing</>,
        link: "/pricing",
      },
      {
        title: <> Recipes</>,
        link: "/recipes",
      },
      {
        title: <> Book a demo</>,
        link: "https://cal.com/mo-retune/30min",
      },
    ],
  },
  {
    title: <> Company</>,
    subSections: [
      {
        title: <> Terms of Service</>,
        link: "/terms-of-service",
      },
      {
        title: <> Privacy </>,
        link: "/privacy",
      },
    ],
  },
];

const Socials = [
  {
    icon: <TwitterIcon css="w-5 h-5 stroke-none fill-white" />,
    link: "https://twitter.com/retune_so",
  },
  {
    icon: <FacebookIcon css="w-5 h-5 stroke-none fill-white" />,
    link: "https://www.facebook.com/profile.php?id=100090198555856",
  },
  {
    icon: (
      <YoutubeIcon
        css="w-5 h-5 stroke-white fill-white [&>*:last-child]:stroke-black"
        width={4}
      />
    ),
    link: "https://www.youtube.com/@re-tune",
  },
];

const Header = () => {
  const router = useRouter();

  return (
    <Y css="px-4 border-b-landing-50/10 border-b">
      <X css="py-2 lg:py-4 items-center justify-between">
        <X css="items-center gap-2">
          <Logo css="h-4 w-4 lg:h-5 lg:w-5" white />
          <Text css="font-bold text-lg lg:text-2xl text-slate-50">re:tune</Text>
        </X>

        <Button
          css="h-8 lg:h-10 rounded-full font-medium text-sm lg:text-base text-landing-50"
          onClick={() => {
            // hack
            router.refresh();
            router.push("/sign-in");
          }}
        >
          Sign up
        </Button>
      </X>
    </Y>
  );
};

const IntroVideo = () => {
  const [playVideo, setPlayVideo] = useState(false);

  return (
    <X css="hug justify-center ">
      <Frame css="grow aspect-[1.463] max-w-4xl rounded-lg z-50 select-none bg-gradient-to-r from-[#cb5bdd] to-[#276bef] p-px overflow-hidden">
        {playVideo ? (
          <Y css="grow min-h-[300px] rounded-[inherit]">
            <Iframe
              width="700"
              height="650"
              src="https://www.youtube.com/embed/V0HP3pY7ZbU"
              title="Complete re:tune walkthrough - the platform to build your AI apps"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
            />
          </Y>
        ) : (
          <>
            <Frame.Layer css="inset-[1px] justify-center overflow-hidden">
              <Image
                priority
                css=""
                src={"/assets/images/home/intro-video.jpg"}
                alt="intro video"
              />
            </Frame.Layer>
            <Frame.Layer css="inset-[1px] items-center justify-center">
              <Image
                priority
                css="cursor-pointer w-20 h-20"
                src={"/assets/images/home/play.png"}
                alt="retune demo video"
                onClick={() => setPlayVideo(!playVideo)}
              />
            </Frame.Layer>
          </>
        )}
      </Frame>
    </X>
  );
};

const Hero = () => {
  const router = useRouter();

  return (
    <Frame direction={"y"} css="overflow-visible">
      <Header />
      <Y css=" py-5 gap-5">
        <Y css="text-center justify-center items-center gap-6 lg:gap-8 py-10">
          <Text css="font-normal text-sm leading-7 text-neutral-200 lg:text-lg -mb-3">
            {Content1.header.whatItIs}
          </Text>
          <Text css="font-semibold text-[2.5rem] tracking-tight leading-none text-center text-neutral-50 max-w-[12ch] sm:max-w-[18ch] lg:max-w-[18ch] lg:text-[4rem]">
            {Content1.header.clearOutcome}
          </Text>
          <Text css="text-lg lg:text-2xl text-center text-neutral-300 font-extralight leading-6 tracking-wide max-w-[30ch] sm:max-w-[45ch] ">
            Everything you need to transform your business with AI, from custom
            chatbots to autonomous agents.
          </Text>
          <Button
            css="rounded-full h-10 lg:h-14 font-normal text-sm lg:text-lg leading-5 gap-1 mt-4"
            onClick={() => {
              // hack
              router.refresh();
              router.push("/sign-in");
            }}
          >
            Get access <ChevronRightIcon css="stroke-2 h-4 lg:h-6" />
          </Button>
        </Y>
      </Y>
      <Frame.Layer css="h-[65%] top-[-30%] left-[-20%] right-[-24%]">
        <Image
          priority
          alt=""
          src={"/assets/images/home/header-mesh.svg"}
          css="origin-center opacity-50 blur-[120px]"
          fit="fill"
        />
      </Frame.Layer>
    </Frame>
  );
};

const FeatureSetOne = () => (
  <Frame css="py-20 lg:px-20 overflow-visible">
    <Y css="gap-10">
      <Y css="justify-center items-center gap-6">
        <Y css="gap-4 p-9 hug items-center">
          <Text css="font-medium text-[2.25rem] tracking-tight leading-none text-center text-neutral-50 max-w-[12ch] sm:max-w-[18ch] lg:max-w-[18ch] lg:text-[3.5rem]">
            {Content2.header.overview}
          </Text>
          <Text css="text-lg lg:text-2xl text-center text-neutral-300 font-light leading-6 tracking-wide max-w-[30ch] sm:max-w-[45ch]">
            {Content2.header.subheader}
          </Text>
        </Y>
        <Y css="gap-8">
          <X css="grow gap-8 wrap md:flex-nowrap p-1">
            <Frame
              direction="y"
              css="rounded-3xl aspect-[0.95] sm:aspect-[1.125] grow md:basis-1/2 bg-gradient-to-b from-indigo-400/40 hover:to-[#cc5bdd] p-px"
            >
              <Y css="grow hug md:pt-8 md:pl-8 lg:pt-11 lg:px-11 md:gap-14 rounded-3xl">
                <Y css="p-8 md:p-0 font-normal text-neutral-50 gap-2">
                  <Text css="text-2xl tracking-tight">
                    {Content2.firstSolution.title}
                  </Text>
                  <Text css="text-md tracking-tight text-neutral-400">
                    {Content2.firstSolution.description}
                  </Text>
                </Y>

                <X css="grow pl-6 relative min-h-full">
                  <Image
                    alt="chatWidget"
                    src={"/assets/images/home/chat-widget.png"}
                    css="absolute inset-0 scale-[1.2] origin-top-left h-auto w-auto"
                    fit="contain"
                    position="top left"
                  />
                </X>
              </Y>

              <Frame.Layer css="inset-[1px] bg-slate-950 rounded-3xl" />

              <Frame.Layer css="aspect-square top-[5%] left-[5%] right-[-10%] ">
                <Image
                  alt=""
                  src={"/assets/images/home/circle.png"}
                  css="origin-center opacity-20 blur-[80px]"
                  fit="fill"
                />
              </Frame.Layer>
            </Frame>

            <Frame
              direction="y"
              css="rounded-3xl aspect-[0.95] sm:aspect-[1.125] grow md:basis-1/2 bg-gradient-to-b from-indigo-400/40 hover:to-[#cc5bdd] p-px"
            >
              <Y css="grow hug md:pt-8 md:pl-8 lg:pt-11 lg:px-11 md:gap-14 rounded-3xl">
                <Y css="p-8 md:p-0 font-normal text-neutral-50 gap-2">
                  <Text css="text-2xl tracking-tight">
                    {Content2.secondSolution.title}
                  </Text>
                  <Text css="text-md tracking-tight text-neutral-400">
                    {Content2.secondSolution.description}
                  </Text>
                </Y>

                <X css="w pl-6 relative min-w-full min-h-[300px] md:min-h-ful">
                  <Image
                    alt="chatWidget"
                    src={"/assets/images/home/screen-embedding.png"}
                    css="absolute inset-0 scale-[1.2] origin-top-left h-auto w-auto"
                    fit="cover"
                    position="top left"
                  />
                </X>
              </Y>

              <Frame.Layer css="inset-[1px] bg-slate-950 rounded-3xl" />

              <Frame.Layer css="aspect-square top-[5%] left-[5%] right-[-10%] ">
                <Image
                  alt=""
                  src={"/assets/images/home/circle.png"}
                  css="origin-center opacity-20 blur-[80px]"
                  fit="fill"
                />
              </Frame.Layer>
            </Frame>
          </X>
          <Frame
            direction="y"
            css="rounded-3xl md:aspect-[2.75] grow md:basis-1/2 bg-gradient-to-b md:bg-gradient-to-r from-indigo-400/40 to-indigo-400/10 md:to-indigo-400/0 hover:to-[#cc5bdd] p-px"
          >
            <Y css="md:flex-row grow hug px-8 md:py-0 py-8 gap-8 md:gap-0 rounded-3xl">
              <Y css="md:px-8 pb-0 md:p-0 font-normal text-neutral-50 gap-2 justify-center">
                <Text css="text-2xl tracking-tight">Integrate everywhere</Text>
                <Text css="text-md tracking-tight text-neutral-400">
                  Supercharge all your favorite tools and platforms with AI, all
                  in one place.
                </Text>
              </Y>

              <X css="grow relative basis-6/12 aspect-[451/321]">
                <Image
                  alt="chatWidget"
                  src={"/assets/images/home/integration.png"}
                  css="md:origin-center grayscale-[95%]"
                  fit="contain"
                />
              </X>
            </Y>

            <Frame.Layer css="inset-[1px] bg-slate-950 rounded-3xl" />

            <Frame.Layer css="aspect-square top-[5%] left-[5%] right-[-10%] ">
              <Image
                alt=""
                src={"/assets/images/home/circle.png"}
                css="origin-center opacity-20 blur-[80px]"
                fit="fill"
              />
            </Frame.Layer>
          </Frame>
        </Y>
      </Y>
    </Y>

    <Frame.Layer css="h-[15%] top-[5%] left-[10%] right-[10%]">
      <Image
        alt=""
        src={"/assets/images/home/section-one.png"}
        css="origin-center opacity-40 blur-[100px]"
        fit="fill"
      />
    </Frame.Layer>
  </Frame>
);

const PricingSection = ({
  title,
  subtitle,
  price,
  features,
}: PricingProps & { reverse: boolean }) => {
  const router = useRouter();

  return (
    <Y
      css={[
        "items-start justify-center text-neutral-50 hug basis-1/3 min-w-[80vw] sm:min-w-[340px] p-2 lg:p-4 grow",
      ]}
    >
      <Y
        css={[
          "bg-gradient-to-t grow hug rounded-xl p-px from-indigo-400/30 to-indigo-400/50",
          title === "Business" ? "from-[#cb5bdd] to-[#276bef]" : "",
        ]}
      >
        <Y css="hug grow bg-landing-900 gap-10 p-8 lg:p-11 rounded-xl justify-between">
          <Y css="gap-6 xl:gap-8">
            <Y css="gap-1">
              <Text css=" text-base font-light text-neutral-400">{title}</Text>
              <Text css=" text-2xl font-bold">{price}</Text>
              <Text css=" text-sm font-light text-neutral-400">{subtitle}</Text>
            </Y>
            <Y css="gap-3 ">
              {features.map((features, index) => (
                <X key={index} css="gap-2 ">
                  <CheckSquareIcon css="w-4 h-4" />
                  <Text key={index} css="text-sm font-light">
                    {features}
                  </Text>
                </X>
              ))}
            </Y>
          </Y>
          <LinkButton
            href={`/sign-in${
              title === "Grow" || title === "Max" ? "?next=subscriptions" : ""
            }`}
            css={[
              "justify-between rounded-full h-12 ring-1",
              title === "Business"
                ? "shadow-md"
                : "bg-neutral-800 ring-neutral-100/20",
            ]}
          >
            <Text css="">
              {title === "Hobby" ? "Get started" : "Start free trial"}
            </Text>
            <ArrowRightIcon css="w-4 h-4 stroke-2" />
          </LinkButton>
        </Y>
      </Y>
    </Y>
  );
};

const Pricing = () => (
  <Frame css="rounded-2xl justify-center">
    <Y css="gap-14 py-11 hug items-center">
      <Orange css="text-5xl leading-[3.5rem] font-medium">Pricing</Orange>

      <Y css="gap-4">
        <X css="justify-start wrap">
          {pricing.map((option, index) => (
            <PricingSection reverse={false} key={index} {...option} />
          ))}
        </X>
        <LinkButton
          variant="link"
          css="text-primary-300 text-lg"
          href="/pricing"
        >
          See all plans <ArrowRightIcon css="ml-1 w-5 h-5 stroke-[3]" />
        </LinkButton>
      </Y>
    </Y>
  </Frame>
);

const Testimonial = () => (
  <Frame>
    <Y css="gap-7 pt-28">
      <Y css="gap-4">
        <Text css="font-black text-[#EBECF3] text-4xl text-center ">
          <Orange> Feedback </Orange> from our users
        </Text>

        <Text css=" text-base color-[#EBECF3] text-center ">
          why our users love re:tune
        </Text>
      </Y>

      <X css="gap-x-7 gap-y-11 items-center">
        {Testimonials.map((testimonial, index) => (
          <Y
            key={index}
            css="px-7 py-4 gap-7 rounded-xl bg-indigo-600 grow justify-between"
          >
            <Text css="text-xs text-[#EBECF3]">{testimonial.description}</Text>
            <X css="gap-4">
              <Image alt="avatar" src={testimonial.userIcon} css="w-6 h-6" />
              <Y css="justify-center">
                <Text css="text-xl font-semibold text-[#EBECF3]">
                  {testimonial.name}
                </Text>
                <Text css="text-xs text-[#EBECF3]">
                  {testimonial.companyName}
                </Text>
              </Y>
            </X>
          </Y>
        ))}
      </X>
    </Y>
    <Frame.Layer css=" h-[35%] top-[10%] left-[20%] right-[24%]">
      <Image
        alt=""
        src={"/assets/images/home/footer-mesh-one.png"}
        css="origin-center opacity-40 blur-[80px]"
        fit="fill"
      />
    </Frame.Layer>
  </Frame>
);

// todo: link to proper pages
const Footer = () => (
  <Y css="gap-16 hug md:flex-row">
    <Frame direction="y" css="gap-4 basis-2/5 justify-between">
      <Logo css="w-6 h-6" white />

      <X css="md:justify-start gap-4">
        {Socials.map((social, index) => (
          <X key={index}>
            <Button
              css="p-0"
              variant="link"
              onClick={() => window.open(social.link, "_blank")}
            >
              {social.icon}
            </Button>
          </X>
        ))}
      </X>
    </Frame>

    <Frame direction="y" css="basis-3/5">
      <Y css="gap-4 grow">
        <X css="justify-between gap-4 [&_a]:no-underline">
          {FooterSections.map((section, index) => (
            <Y css="gap-3" key={index}>
              <Text css="text-sm font-normal text-neutral-50">
                {section.title}
              </Text>
              <Y css="">
                {section.subSections.map((subSection, index) => (
                  <Button
                    key={index}
                    variant="link"
                    css="text-sm text-neutral-400 p-0 h-8 justify-start font-light"
                  >
                    <Link href={subSection.link}>{subSection.title}</Link>
                  </Button>
                ))}
              </Y>
            </Y>
          ))}
        </X>
      </Y>
    </Frame>
  </Y>
);

function CTA() {
  const router = useRouter();

  return (
    <Frame css="h-[600px] sm:h-[480px] mask-gradient-circle from-landing-950">
      <Frame.Layer css="aspect-[1] bg-gradient-to-r from-[#CB5CDD] to-[#276BF0] rounded-b-full bottom-[50%] left-[30%] right-[30%] blur-[100px]" />
      <Frame.Layer css="aspect-[10/7] -left-1/2 -right-1/2 bottom-[70%] bg-landing-950 !rounded-[50%] border-b border-b-landing-50/30" />
      <Y css="absolute top-[30%] left-1/2 transform -translate-x-1/2 -translate-y-12 items-center gap-6">
        <Logo
          css="w-24 h-24 p-6 rounded-[28px] ring-1 ring-neutral-200/40"
          white
          background
        />
        <Y css="gap-4 items-center text-center">
          <Text css="text-3xl font-semibold text-[#EBECF3]">
            The missing platform to build your AI apps
          </Text>
          <Text css="text text-[#EBECF3]">
            Use it to build your own AI powered apps. No coding required.
          </Text>
          <X css="gap-4 wrap justify-center">
            <Button
              css="min-w-[100px] rounded-full"
              onClick={() => {
                // hack
                router.refresh();
                router.push("/sign-in");
              }}
            >
              Get access
            </Button>

            <Button
              css="min-w-[100px] rounded-full bg-neutral-800 text-neutral-200 ring-1 ring-neutral-50/10"
              variant="secondary"
              data-cal-link="mo-retune/30min"
            >
              Book a demo
            </Button>
          </X>
        </Y>
      </Y>
    </Frame>
  );
}

export function HomePageClient() {
  useEffect(() => {
    (async () => {
      const cal = await getCalApi();
      console.log("cal.com loaded");
      cal?.("ui", {
        theme: "dark",
        styles: { branding: { brandColor: "#111727" } },
      });
    })();
  }, []);

  const { track } = useMixpanel();

  useEffect(() => {
    track(EVENT_CONST.HOMEPAGE_VIEWED);
  }, [track]);

  return (
    <>
      <ScrollArea css="h-screen">
        <Y css="grow items-center min-w-[320px] bg-landing-950 overflow-hidden">
          <Y css="max-w-7xl w-full lg:px-10 px-4">
            <Y css="hug">
              <Hero />
              <IntroVideo />
            </Y>

            <FeatureSetOne />
            <Pricing />
            <CTA />
          </Y>

          <Y css="max-w-7xl w-full lg:px-10 px-4 py-24">
            <Footer />
          </Y>
        </Y>
      </ScrollArea>
    </>
  );
}
